

$wizard-arrow-size: 16px;
$wizard-arrow-sep: 6px;

@mixin wizard-arrow($color) {
  position: absolute;
  top: 0;
  width: 0px;
  height: 0px;
  border-top: $wizard-arrow-size inset transparent;
  border-bottom: $wizard-arrow-size inset transparent;
  border-left: $wizard-arrow-size solid $color;
  content: "";
}

.nav-wizard {
  margin-bottom: 20px;

  &.nav-tabs {
    border-bottom: 0;
  }

  > li {
    float: none;
    display: table-cell;
    width: 1%;

    > a, > span {
      position: relative;
      display: block;
      padding: 7px 0;
      height: 32px;
      background: $grey-color;
      color: #000;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      text-decoration: none !important;
      text-transform: uppercase;
      border-radius: 0;
      border: none;
    }

    &:not(:first-child) > * {
      padding-left: $wizard-arrow-size;

      &:before {
        @include wizard-arrow(#fff);
        left: 0;
      }
    }

    &:not(:last-child) > * {
      margin-right: $wizard-arrow-sep;

      &:after {
        @include wizard-arrow($grey-color);
        right: -$wizard-arrow-size;
        z-index: 2;
      }
    }

    &:hover > a, &:hover > span {
      background-color: darken($grey-color, 10%);
      &:before { border-right-color: darken($grey-color, 10%); }
      &:after { border-left-color: darken($grey-color, 10%); }
    }

    &.completed {
      > a, > span {
        background-color: lighten($primary-color, 50%);
        color: #000;
        &:after { border-left-color: lighten($primary-color, 50%); }
      }
      &:hover > a, &:hover > span {
        background-color: lighten($primary-color, 40%);
        &:before { border-right-color: lighten($primary-color, 40%); }
        &:after { border-left-color: lighten($primary-color, 40%); }
      }
    }

    &.active {
      pointer-events: none;
      > a, > span {
        background-color: $primary-color !important;
        color: #fff;
        border: none;
        &:after { border-left-color: $primary-color !important; }
      }
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      > a, > span {
        color: rgba(0, 0, 0, 0.35)
      }
    }

    /*
    &.disabled > a, &.disabled > span {
      pointer-events: none;
      &, &:hover, &:focus {
        cursor: default;
        color: rgba(0, 0, 0, 0.35);
        background-color: $grey-color;
      }
      &:after { border-left-color: $grey-color; }
    }

    &.active > a, &.active > span {
      pointer-events: none;
      &, &:hover, &:focus {
        cursor: default;
        color: #fff;
        background-color: $primary-color;
      }
      &:after { border-left-color: $primary-color; }
    }

    &.completed > a, &.completed > span {
      background-color: lighten($primary-color, 50%);
      &:before { border-right-color: lighten($primary-color, 50%); }
      &:after { border-left-color: lighten($primary-color, 50%); }
    }

    &.completed:hover > a, &.completed:hover > span {
      background-color: lighten($primary-color, 40%);
      &:before { border-right-color: lighten($primary-color, 40%); }
      &:after { border-left-color: lighten($primary-color, 40%); }
    }
    */
  }
}

.page-header + .nav-wizard { margin-top: -10px; }
