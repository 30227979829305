
//.modal-open { overflow: visible; padding-right: 0 !important; }

.modal {
  display: flex;
  padding: 0 !important;
  align-items: center;
  justify-content: center;
  overflow-y: scroll !important;

  z-index: 2050 !important;
  &.modal-sm, &.modal-lg { width: auto !important; }
}
.modal-dialog {
  margin: 0 !important;
  width: 100%;
  max-height: 100%;
}
.modal-content {
  margin: 10px;
  border: 0;
  @include border-radius(0);
}
.modal-body {
  padding: 15px;
  p:last-child { margin-bottom: 0; }
}
.modal-header {
  padding: 15px;
  background: $primary-color;
  color: #fff;
  border: 0;

  h1 {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    color:black;
  }
  h4{
    color: #fff;
  }
  .fa {
    cursor: pointer;
    opacity: 0.5;
    &:hover { opacity: 1; }
  }
}

.modal-footer {
  padding: 10px;
  background: lighten($grey-color, 10%);
  border: 0;
}

@media (min-width: $screen-sm-min) {
  .modal-dialog { width: $modal-md; }
  .modal-sm .modal-dialog { width: $modal-sm; }
}

@media (min-width: $screen-md-min) {
  .modal-lg .modal-dialog { width: $modal-lg; }
}

