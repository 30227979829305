.collapsible {
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    h4 {
        cursor:pointer;
        width: 100%;
    }

    i {
        font-size: 24px;
        color: #c7c7c7;
    }
}

hr {
    border:none;
    height: 3px!important;
    color: #f2e6da;
    background-color: #f2e6da;
}

.buying-top {
    display: flex;
    justify-content: space-between;

    a.btn.btn-primary {
        display:flex;
        align-items: center;
        i.create-account-chevron {
            margin-left: 10px;
            font-size: 18px;
        }
    }
    

    ul {
        list-style-type: none;
        margin: 0!important;

        li {
            margin-bottom: 8px;
        }

        li.ico1 {
            background: url('/images/zipperbuy_buying_icons/1.png') no-repeat left top;
            background-size: contain;
            padding-left: 40px;
            padding-top: 3px;
            height: 32px;
            p {
                padding-top: 3px;
            }
        }

        li.ico2 {
            background: url('/images/zipperbuy_buying_icons/2.png') no-repeat left top;
            background-size: contain;
            padding-left: 40px;
            padding-top: 3px;
            height: 32px;
            p {
                padding-top: 3px;
            }
        }

        li.ico3 {
            background: url('/images/zipperbuy_buying_icons/3.png') no-repeat left top;
            background-size: contain;
            padding-left: 40px;
            padding-top: 3px;
            height: 32px;
            p {
                padding-top: 3px;
            }
        }

        li.ico4 {
            background: url('/images/zipperbuy_buying_icons/4.png') no-repeat left top;
            background-size: contain;
            padding-left: 40px;
            padding-top: 3px;
            height: 32px;
            p {
                padding-top: 3px;
            }
        }

        li.ico5 {
            background: url('/images/zipperbuy_buying_icons/5.png') no-repeat left top;
            background-size: contain;
            padding-left: 40px;
            padding-top: 3px;
            height: 32px;
            p {
                padding-top: 3px;
            }
        }

        li.ico6 {
            background: url('/images/zipperbuy_buying_icons/6.png') no-repeat left top;
            background-size: contain;
            padding-left: 40px;
            padding-top: 3px;
            height: 32px;
            p {
                padding-top: 3px;
            }
        }
    }

    @media (max-width: 1024px) {
        flex-wrap: wrap;

        .center {
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}