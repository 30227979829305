
.device-xs, .device-sm, .device-md, .device-lg {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #FFFFFF;
  z-index: 1000;
}

.device-xs { background-color: #FF0000; }
.device-sm { background-color: #9400D3; }
.device-md { background-color: #B8860B; }
.device-lg { background-color: #008000; }

.panel-default {
  background: lighten($grey-color, 5%);
  border: 0;
  @include border-radius(0);

  & > .panel-heading {
    padding: 15px;
    background: $primary-color;
    color: #fff;
    border: 0;
    @include border-radius(0);

    h1 {
      margin: 0;
      font-weight: bold;
      font-size: 16px;
    }
  }

  & > .panel-body, & > .panel-footer {
    padding: 15px;
    background: lighten($grey-color, 5%);
    border: 0;
    @include border-radius(0);
  }

  .panel-body p:last-child { margin-bottom: 0; }
}

.panel-scrollable {
  max-height: 120px;
  overflow-y: scroll;
}

.fa { min-width: 1em; min-height: 1em; }

a { cursor: pointer; }
a, button {
  &:focus {
    outline:0 !important;
  }
}

ol, ul { margin-top: 15px; }
ol > li, ul > li { padding-bottom: 10px; }
.nav > li { padding-bottom: 0; }

body {
 font-family: $pfont;
 font-size: 13px;
 font-weight: normal;
 line-height: 1.2;
 overflow-y: scroll;
}

abbr[title] { cursor: default; border: 0; }

main { padding: 20px 0; }

.strong { font-weight: bold; }
.nowrap { white-space: nowrap; }

.table tr.vert-align > td {
  vertical-align: middle;
}

.page-header {
  display: block;
  margin: 0 0 20px 0;
  padding: 15px;
  background: $grey-color;
  text-decoration: none !important;

  h1, h2 {
    margin: 0;
    font-weight: bold;
    text-transform: uppercase
  }

  h1 { font-size: 16px; }
  h2 { font-size: 14px; }

}

a.page-header { cursor: pointer; }

.page-header, .panel-heading {
  .btn-group.pull-right {
    top: -7px;
    left: 7px;
  }
}

.tag {
  display: inline-block;
  margin: 5px 5px 0 0;
  padding: 3px 3px 3px 5px;
  font-size: 0;
  line-height: 1;
  text-align: center;
  background: lighten($primary-color,10%);
  color: #fff;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;

  > * { display: inline-block; font-size: 12px; }
  strong:empty, span:empty { display: none; }
  strong { font-weight: bold; }
  span { margin-left: 5px; }
  i { cursor: pointer; margin-left: 5px; }
}

.latest-news {
  background:$grey-color;
  margin: 0 0 20px 0;
  padding:15px;
  h4{
    font-weight:700;
    margin:0;
    text-transform:uppercase;
    text-align:right;
    font-size:15px;
  }
}

@keyframes bidflash {
  0% { color: $danger-color; }
  30% { color: $danger-color; }
  100% { color: $primary-color; }
}

.listing {
  .listing-tags { margin-top: 5px; }
  .listing-nav, .listing-items { margin-top: 10px; }

  .listing-header {
    .page-header { margin: 0; }

    .auction-header .panel-body {
      background: lighten($primary-color, 10%);
      color: #fff;
    }
  }

  .listing-nav {
    h5 { margin: 0; }
  }

  .listing-items
  {
    min-height: 350px;

    .item {
      float: none !important;
      //margin: 0 0 20px 0;

      & + .item {
        border-top: 2px solid #AF0000;
      }

      p:not(.item-image) {
        margin: 0;
        line-height: 1;
        .fa { margin-right: 5px; min-width: 13px; text-align: center; }
      }

      .item-lot-number, .item-title, .item-type, .item-location, .item-end-time { margin-top: 5px; }

      .item-current-bid {
        color: $primary-color;
        font-size: 18px;
        font-weight: bold;
        &.active { animation: 2s ease-out bidflash; }
      }
      .item-bid-count { font-size: 11px; }
      .item-current-bidder {
        font-size: 14px;
        margin-top: 7px;
      }

      .item-actions {
        font-size: 0;
        .btn { margin: 0 0 0 8px; }
      }

      &.item-lot .item-lot-number, &.item-auction .item-title {
        margin: 10px 0;
        font-size: 18px;
        font-weight: bold;
        padding-left: 20px !important;
      }

      &.item-lot .item-title {
        margin: 10px 0;
        font-weight: bold;
        font-size: 14px;
      }

      .btn-primary .fa-gavel { margin-right: 5px; }

      .btn-link {
        padding: 0;
        font-size: 9px;
        line-height: 10px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none !important;

        .fa {
          display: block;
          font-size: 24px;
          text-align: center;
        }
      }
    }

    .item-row {
      @extend .row;
      display: flex;
      flex-wrap: wrap;

      &.grid {
        .item {
          @extend .col-sm-3;
          padding-bottom: 35px;

          .item-bids {
            position: absolute;
            bottom: 7px;
            left: 10px;
          }
          .item-actions {
            position: absolute;
            bottom: 0;
            right: 10px;
            font-size: 0;
          }
        }
      }

      .lot-row {
        &:nth-child(odd) {
          background-color: #f5f5f5;
        }
      }

      &.list {
        .item {
          @extend .col-xs-12;

          &.item-auction:nth-child(odd) {
            background-color: #f5f5f5;
          }

          .item-bids, .item-actions {
            float: right;
            position: relative;
            margin: 0 0 10px 10px;
          }
          .item-bids {
            top: 11px;
            text-align: right;

            &.bidbox {
              padding: 15px;
              width: 205px;
              text-align: center;
              background-color: #fff;

              .item-current-bid-label {
                font-weight: bold;
                font-size: 14px;
              }
              .item-current-bid {
                font-size: 24px;
                margin: 3px auto 5px;
              }
              .item-bid-count {
                color: #111;
                font-size: 14px;
              }

              @media (max-width: 1200px) {
                float: none;
                margin: 0 auto;
              }
            }
          }
          .item-actions {
            top: 3px;
          }
          /*
          .item-bids {
            position: absolute;
            top: 11px;
            right: 140px;
            text-align: right;
          }
          .item-actions {
            position: absolute;
            top: 3px;
            right: 10px;
            font-size: 0;
          }
          */

          img {
            //float: left;
            //margin: 0 15px 0 0;
            //max-width: 25%;
          }
        }
      }
    }
  }
}

#watchlist_listing.listing .listing-items {
  .item-row.grid .item {
    @extend .col-md-3;
    @extend .col-sm-4;
  }

  .item-row.list {
    & .item.item-lot:nth-child(odd) {
      background-color: #f5f5f5;
    }
    img {
      max-width: 20%;
    }
  }
}

#registration {
  .panel-default { margin-top: 10px; }
}

.pagination {
  display: block;
  margin: 0;

  li {
    a { cursor: pointer !important; }
    a, a:hover, span {
      color: #333 !important;
      font-weight: normal;
      line-height: 1 !important;
      border: 0 !important;
      padding: 0 5px !important;
      background: none !important;
    }

    &.active > a, &.active > a:hover {
      font-weight: bold;
    }
  }

  .prev, .next {
    a {
      color: $primary-color !important;
      span { color: #333 !important; }
    }
  }
}

.auction-item-info
{
  .media-main, .media-list img { cursor: pointer; }
  .media-main { margin-bottom: 6px; width: 100%; }
  .media-list
  {
    margin: 0 -3px;
    .col-md-2.col-xs-4
    {
      position: relative;
      margin-bottom: 6px;
      padding: 0 3px;
    }

    .media-more
    {
      pointer-events: none;
      position: absolute;
      background-color: rgba(0, 0, 0, .35);
      top: 0;
      bottom: 0;
      left: 3px;
      right: 3px;
      color: #fff;

      span, i
      {
        position: absolute;
        margin-top: -10px;
        top: 50%;
        left: 0;
        right: 0;
        font-size: 20px;
        line-height: 100%;
        text-align: center;
      }
    }
  }

  h3
  {
    margin: 0 0 20px 0;
    font-weight: bold;
    div + div { margin: 10px 0 0 0; font-weight: normal; }
  }

  h4 {
    font-weight: bold;
  }


  .gray-bg {
    background:$grey-color;
    padding:15px;
    margin-bottom:20px;
  }

  .item-bid
  {
    background: $primary-color;
    margin-bottom: 10px;
    padding: 15px;
    @include border-radius(4px);
    color: #fff;

    p, label, input, .btn { line-height: 28px; }

    p { margin: 0; font-size: 24px; }
    label { margin: 0 10px 0 0; float: left; }
    input, .btn { padding: 0; height: auto; border: 0; @include box-shadow(none); }
    .btn { padding: 5px 0; font-weight: bold; white-space: normal; line-height: 14px; }
    i { margin-right: 6px; font-size: 18px; }

    input
    {
      background-color: transparent;
      color: #fff;
      text-align: right;
      @include input-placeholder { color: rgba(255, 255, 255, 0.75); }
      &:focus { @include box-shadow(none); }
    }
  }

  .item-schedule
  {
    label, p
    {
      display: block;
      margin: 0 0 4px 0;
      padding: 4px 6px;
    }

    label { margin: 0; text-align: right; }
    p { background-color: lighten($grey-color, 5%); }


    .item-share, .item-actions
    {
      margin-top: 10px;
      font-size: 20px;
      line-height: 1;

      li
      {
        padding: 0 2px;
        text-align: center;
      }

      a
      {
        cursor: pointer;
        display: block;
        margin: 0 auto;
        text-decoration: none !important;
      }

      .fa { display: block; }
    }

    .item-share
    {
      .email .fa-circle { color: #000; }
      .facebook .fa-circle { color: #3B5998; }
      .twitter .fa-circle { color: #55acee; }
      .google-plus .fa-circle { color: #d34836; }
    }

    .item-actions
    {
      font-size: 9px;
      text-transform: uppercase;

      .fa { position: relative; margin: 0 0 5px 0; font-size: 35px; }

      .print { color: #000; }
      .question { color: #3B5998; .fa { top: 2px; } }
      .watchlist { color: #d34836; .fa { top: 5px; } }
    }
  }
}

.list-inline.list-cards {
  margin: 0;
  li {
    padding: 0;
    img { margin: 10px 10px 0 0 }
  }
}

.row-account {
  .profile-info {
    display: flex;

    .general-info {
      flex: 1 1 auto;
    }
  }
}

a.mini_gray_button, input.mini_gray_button {
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #555;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: #FFF;
  padding: 3px 10px 4px 10px;
  text-decoration: none;

  &:hover {
    color: #fff;
    background: #777;
  }
}

.timer {
  display: flex;

  & > img { flex: 0 0; }
  & > div { flex: 1 1 100%; }
}

.list-time-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & > div { flex: 1 1 100%; }
}

.time-box {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .time-element {
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    color: #AF0000;

    span {
      font-size: 12px;
    }
  }

  .text-danger {
    font-size: 18px;
    flex: 1 1 100%;
    padding-left: 7px;
  }
}

.spread, .split, .service-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.spread > * {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1 0 0;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
}

.flex-grow {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
}

.spread.vcenter > *, .split.vcenter > * {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;

  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-bottom {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-align: end;
  -mox-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.wideform_admin.services {
  padding: 0;

  .split {
    padding: 15px;
    width: 100%;

    .flex-grow:not(.no-flex) {
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;

      -moz-box-orient: vertical;
      -ms-box-orient: vertical;
      -webkit-flex-direction: column;
      flex-direction: column;
    }

    .service-icon {
      padding: 0 20px;
      max-width: 200px;
      width: calc(18% + 40px);

      img {
        width: 100%;
      }
    }

    @media (max-width: 520px) {
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;

      .service-icon {
        max-width: 100%;
        width: 100%;
        text-align: center;
        order: -1;

        img { width: 80%; }
      }
    }
  }
  .split + .split:not(.more-info) {
    border-top: 2px solid #AF0000;
  }

  .gray-bg {
    background-color: #f6f6f6;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-left: 1em;
      text-indent: -.7em;

      &:before {
        vertical-align: middle;
        content: "\002022";
        color: #AF0000;
        font-size: 23px;
      }
    }
  }
}

.wideform_admin.selling, .wideform_admin.buying {
  .title-bar {
    background-color: #AF0000;
    color: white;
    font-size: 23px;
    padding: 10px 0;
    margin-top: 15px;
  }

  img { max-height: 300px; }
  .circle {
    border-radius: 100%;
    border: 1px solid #000;
    display: inline-block;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    max-width: 300px;
  }

  a.service {
    h3 { margin-top: 5px; }
    &:hover, &:focus { text-decoration: none; }

    display: inline-block;
    margin: 0 10px;
    font-size: 18px;
    width: 25%;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-left: 1em;
      text-indent: -.7em;
      font-size: 12px;

      &:before {
        vertical-align: middle;
        content: "\002022";
        color: #AF0000;
        font-size: 23px;
      }
    }
  }

  dl {
    dt { color: #AF0000; }
    
    dt + dd {
      margin-top: 10px;
    }

    dd + dt {
      margin-top: 20px;
    }
  }
}

// .item-image{
//   text-align: center;
// }
// .item-image img{
//   max-height: 285px;
//   display: inline-block;
// }
// .listing-items .item-image {
//   background-color: white;
//   border: 1px solid #999;
// }

.item-image {
  background-color: white;
  border: 1px solid #999;
  text-align: center;
  margin: 0 auto;

  img { vertical-align: middle; display: inline-block; }

  .listing-items & {
    max-width: 218px;
    height: 164px;
    line-height: 164px;

    & > a {
      display: block;
      height: 162px;
      line-height: 162px;
    }

    img { max-height: 150px; }
  }
}

.item-image-slider {
  background-color: white;
  border: 1px solid #999;
  text-align: center;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 auto;

  img { vertical-align: middle; display: inline-block; }

  a.next, a.prev {
    background-color: rgba(0, 0, 0, .35);
    color: rgba(255, 255, 255, 0.75);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 25px;
  }
  a.next { right: calc(0px - 25px); transition: right .5s; }
  a.prev { left: calc(0px - 25px); transition: left .5s; }

  &:hover {
    a.next { right: 0; }
    a.prev { left: 0;}
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    transition: margin-left .5s;
    li { margin: 0; padding: 0; text-align: center }
  }
  .listing-items & {
    width: 218px;
    height: 164px;
    line-height: 164px;

    img { max-height: 162px; max-width: 216px;  }

    ul li {
      display: inline-block;
      width: 216px;
      height: 162px;
      line-height: 162px;

      & > a {
        display: inline-block;
        width: 216px;
        height: 162px;
        line-height: 162px;
      }
    }
  }
  #lot_listing & {
    width: 100%;
    height: 262px;
    line-height: 262px;

    img { max-height: 260px; max-width: 100%; }
    ul {
      width: 100%;
      li {
        display: inline-block;
        width: 100%;
        height: 260px;
        line-height: 260px;
      }
    }
  }
}


ul.attachments {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;

  li::before { @include fa-li; }
  li.pdf::before { content: '\f1c1'; }
  li.doc::before, li.docx::before { content: '\f1c2'; }
  li.xls::before, li.xlsx::before { content: '\f1c3'; }
  li.ppt::before, li.pptx::before { content: '\f1c4'; }
  li.zip::before { content: '\f1c6'; }
}

a.list-lot-image {
  width: 100%;
  height: 250px;
  background-size: contain;
  border: 1px solid #000;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 5px;
}

@media (max-width: 767.9px) {
  .col-sm-6.form-split-border-left {
    border-left: 0;
    padding-left: 0;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    margin-top: 20px;
  }
}

dl.details {
  display: flex;
  flex-wrap: wrap;

  dt {
    width: 200px;
  }
  dd {
    flex: 1 0 auto;
    width: calc(100% - 300px);
  }

  .space-after {
    margin-bottom: 10px;
  }
}

a.termscond {
  color: #009900;
  text-decoration: underline;
}