.container {
  @include container-fixed(50px);

  @media (min-width: $screen-sm-min) { width: $container-sm; }
  @media (min-width: $screen-md-min) { width: 98%; }
  @media (min-width: $screen-lg-min) { width: $container-lg; }
}
.container-fluid { @include container-fixed; }

.row {
  @include make-row;

  &.row-tight {
    margin-left: -5px;
    margin-right: -5px;

    > [class^=col-] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &.row-tighter {
    margin-left: -2px;
    margin-right: -2px;

    > [class^=col-] {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

@include make-grid-columns;
@include make-grid(xs);
@media (min-width: $screen-sm-min) { @include make-grid(sm); }
@media (min-width: $screen-md-min) { @include make-grid(md); }
@media (min-width: $screen-lg-min) { @include make-grid(lg); }

