.featured_listing{
  width: 100%;
  margin: auto;
  .tile_auction{
    padding: 10px;
    background-color: white;
    border: 2px solid darken($primary-text, 15%);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-size: 1.1rem;

    color: $text-color;
    transition: box-shadow .2s cubic-bezier(0.25, 0.8, 0.25, 1);
    .slider-img-container{
      height: 120px;
      width: 193px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        max-height: 120px;
        max-width: 193px;
      }
    }
    .btn.btn-primary{
      border:2px solid darken($primary-text,0%);
      border-radius: 10px;
      font-size: 1.1rem;
    }
    &:hover {
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0,0,0,.5), 0 2px 4px rgba(0,0,0,.6);
      text-decoration: none;

    }
  }

  .sp-arrow{
    &.sp-previous-arrow{
      left:-30px;
    }
    &.sp-next-arrow{
      right: -30px;
    }
    &:before, &:after{
      background-color: $header-color;
    }
  }
}