// pre
@import "variables";

// post
@import "mixins";

@import "grid";
@import "form";
@import "modal";
@import "wizard";
@import "toastr";
@import "photoswipe";
@import "spinners";
@import "header";
@import "footer";

@import "custom";
@import "tabs";

@import "invoice";
@import "sidebar";
@import "auctions";
@import "lots";
@import "lot-details";

@import "flex";

@import "home";
@import "text";
@import "bootstrap-overrides";
@import "featured-slider";
@import "my-bid-status";

@import "buying";

a.no-hover-underline{
  &:hover, &:focus {
    text-decoration: none;
  }
}

#dashtoggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  background: transparent none;
  margin: 8px 15px auto 0;
  display: block;
  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

input[type="text"]:focus{
  outline: 0;
}

/*a {
  color: #00B7FF;
}*/

.wideform_admin {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 7px #bbb;
  margin: 0 auto;
  padding: 8px 8px 8px;
}

ol.tight-li, ul.tight-li {
  padding-left: 1em;
  li { padding-bottom: 0; }
}

/* Bootstrap Color Overrides */



h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: $text-color;
  font-weight: bold;
}

#auctionDiv {
  //border: 1px solid #000;
  //border-radius: 4px;
  padding: 8px;
  margin: 4px;
}

#lotDiv {
  //border: 1px solid #000;
  //border-radius: 4px;
  padding: 8px;
  margin: 4px;
}

/* apply a natural box layout model to all elements */
* { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }

html {
   min-height: 100%;
   width: 100%;
}

body
{
  border: none;
  height: 100%;
  position: relative;
  background: $main-background no-repeat;
}

a
{
   color: #555;
}
a:not(.auction-tile):hover
{
   color: $primary-hover-text;
}
a.btn-white:hover{
  color: inherit;

}
a:not(.btn):hover{
  color: $text-color-hover;
}
a > img
{
   border-style: none;
}

header {
   position: relative;
   width: 100%;
   height: 103px;
   background-color: #1b1b1b;
}

#header_inner
{
   position: relative;
   min-width: 960px;
   max-width: 64%;
   height: 100%;
   margin:auto;
   padding: 0;
   color: #666;
   text-align: right;
}

#bid_title
{
   font-size: 27px;
   font-weight: bold;
   color: rgb(95, 95, 95);
   display: inline-block;
   text-align: right;
   line-height: 11px;
   padding-top: 32px;
}
#bid_title > span
{
   display: block;
}
#bid_title > span:first-child
{
   font-size: 45px;
   line-height: 38px;
}

/*.navbar-brand {
    position: absolute;

    top: 21px;
    left: 0px;
    text-decoration: none;
}

.navbar-brand > span {
   font-size: 52px;
   position: relative;
   top: -13px;
   text-decoration: none;
   border: none;
}

.navbar-brand > span > span:first-of-type {
  font-family: 'Play', sans-serif;
  font-weight: bold;
  color: rgb(162, 162, 162);
  padding: 0 0 0 6px;
}
.navbar-brand > span > span:last-of-type {
  font-family: 'Play', sans-serif;
  color: rgb(240, 240, 240);
} -- CAS 11-10-15 --*/

.bid_links
{
   text-align: center;
}
.bid_links > div > a
{
   padding: 0 2em 0 0;
   font-size: 19px;
   text-decoration: none;
   color: #d0b800;
}


#bid_body,
.bid_links
{
   width: 850px;
   height: 100%;
   margin: 2em auto;
   position: relative;
}

.notextselect {
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;

   cursor: default;
}

#resetBidsContainer
{
    text-align: center;

    margin: 3em 0 0 0;
}

#bid_body
{
   width: 823px;
   text-align: center;
}


.button
{

  background: #D9D9D9 linear-gradient(to bottom, #fff, #e1e1e1);

  color: #111;

   font-weight: 600;
   text-align: center;



   border-radius: 4px;

   border: 1px solid rgb(121, 121, 121);

   box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px 0;

   padding: 3px 6px;
   margin: 2px;
}


.button:hover
{
  background: #777 linear-gradient(to bottom, #e8e8e8, #8d8d8d);

  color: #fff;

   box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px 0;
}


.button:disabled
{
   background-color: #ddd;

   color: #aaa;
}


#auctionTimer input {
  color: green;
}

#currentBid > p > input {
    border: none;
    background-color: #FAFAFA;
    font-size: 1.2em;

    transition: all 0.3s ease;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
#currentBid > span > p {
   width: 360px;
}

#currentBids > input {
    border: none;
    background-color: #fafafa;
    font-size: 1.2em;

    transition: all 0.3s ease;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
#currentBids > span > p {
   width: 360px;
}

div.col-md-12 input#userid {
    border: none;
    background-color: #fafafa;
    font-size: 1.2em;
    width: 340px;

    transition: all 0.3s ease;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}
#currentBid > span > p {
   width: 360px;
}

div#currentBid p {
   font-size: 1.4em;
}

.divider-vertical {
    height: 100px;
    border-left: 1px solid gray;
    float: left;
    opacity: 0.5;
    margin: 0 15px;
}

.form-control-feedback {
  width: 30px !important;
}

#footer {
  color: #ddd;
  padding-top: 20px;
  background: #454545 none repeat scroll 0 0 !important;
  border-top: 1px solid #178acc;
  filter: none;
  padding-right: 15px;
  padding-left: 15px;
}

div#footer.container-full.col-lg-12 div.col-lg-2.text-center h4 {
  color: #fff !important;
}
div#footer.container-full.col-lg-12 div.col-lg-2.text-center h5 a {
  color: #fff !important;
}

div#footer.container-full.col-lg-12 div.col-lg-2.col-lg-offset-3.text-center a {
  color: #fff !important;
}

div#footer.container-full.col-lg-12 div.col-lg-2.text-center span {
  color: #fff !important;
}

.container-full {
  margin: 0 auto;
  width: 100%;
}

.container-full-bg {
  margin: 0 auto;
  width: 100%;
  background: #fff url("/images/content_bg.png") repeat-x scroll 0 0;
  position: relative;
  top: 86px;
  z-index: 1;
}


#dashboard {
  float: right;
  @media (min-width:$screen-md-min) {
    display: none !important;
  }
}

nav.navbar.navbar-inverse div.container .collapse.navbar-collapse ul.nav.navbar-nav.navbar-right li#dropmenu {
  div#topbar_bubble_wrap {
    position: absolute; top: 35px; right: 20px; z-index: 1000; text-align: center; display:none;
    div#topbar_bubble div a {
      color: #000;
    }
  }

  &:hover div#topbar_bubble_wrap {
    display: block;
  }
}


div[id^="auctionDiv"], div[class*=" auctionDiv"] {
    font-size: 14px;
}

div#subnav div.container div.col-lg-4 ul#categories {
  padding-left: 0;
}

.infolink {
    background-color: #d5d5d5;
    border-left: 1px solid #fff;
    cursor: pointer;
    font-size: 13px;
    margin-right: 8px;
    width: 355px;
}
.infolink h1 {
    color: #000;
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
}
.infolink a {
    font-size: 12px;
    text-decoration: none;
}
.infolink:hover {
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #f8da84 0%, #feca37 100%) repeat scroll 0 0;
}

div.container div.col-lg-4 ul#categories {
  padding-left: 2px;
}

#searchspan input {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: #fff;
  background-image: url("../../images/search-sprite.png");
  background-origin: padding-box;
  background-position: 352px -5px !important;
  background-repeat: no-repeat;
  background-size: auto auto;
  border-left: 1px solid #666;
  border-top: 1px solid #666;
  font-size: 17px;
  outline: medium none;
  padding: 10px 36px 8px 10px;
  width: 390px;
}

#mobile_searchspan input {
  background-attachment: scroll;
  background-clip: border-box;
  background-color: #fff;
  background-origin: padding-box;
  background-position: 352px -5px !important;
  background-repeat: no-repeat;
  background-size: auto auto;
  border-left: 1px solid #666;
  border-top: 1px solid #666;
  font-size: 17px;
  outline: medium none;
  padding: 10px 36px 8px 10px;
}

div.container div.col-lg-4 ul#categories hr {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.page-header {
  margin: 0 0 20px !important;
  &.info{
    background:rgba(255,0,0,.3);
  }
}

div#subnav div.container div.page-header div.col-lg-12.well.h3 {
  padding: 4px 0 4px 4px;
}

div#subnav div.container section div.row div.col-lg-3 aside.sidebar form#auction_search a.page-header h1 {
  color: #FFFFFF !important;
}

div#subnav div.container section div.row div.col-lg-3 aside.sidebar form#lot_search a.page-header h1 {
  color: #FFFFFF !important;
}

div#subnav div.container section div.row div.col-md-3 aside.sidebar form#lot_search a.page-header h1 {
  color: #FFFFFF !important;
}

div.container section div.row div.col-md-3 aside.sidebar form#lot_search a.page-header h1 {
  color: #FFFFFF !important;
}

.checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"], .radio input[type="radio"], .radio-inline input[type="radio"] {
  margin-left: 5px !important;
}

.checkbox label, .radio label {
  padding-left: 25px;
}


.checkbox input + label::after {
  line-height: 1.5em;
}

.checkbox-ao {
  input[type=checkbox]:checked, input[type=radio] {
    & + label {
      &::before {
        background-color: #AF0000;
        border-color: #AF0000;
      }
      &::after {
        color: white;
      }
    }
  }
}

.radio-ao {
  input[type=radio] {
    + label {
      &::after {
        background-color: #AF0000;
      }
    }
    &:checked + label {
      &::before {
        border-color: #AF0000;
      }
    }
  }
}



html, body, ul, li {
    font-family: $pfont;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
}
#menu {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: #eee none repeat scroll 0 0;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border: 1px solid #d3d3d3;
  border-right: medium none -moz-use-text-color;
  box-shadow: 0 0 1px #edf9ff inset;
  display: inline-table;
  height: 43px;
  list-style: outside none none;
  padding: 4px 9px;
  width: 140px;
}
#menu li {
    border: medium none;
    display: block;
    float: left;
    margin-right: 30px;
    margin-top: 12px;
    padding: 4px 9px;
    position: relative;
    text-align: center;
}
#menu li:hover {
    background: rgba(0, 0, 0, 0) repeat scroll 0 0;
    border-radius: 7px 7px 0 0;
    padding: 4px 9px;
}
#menu li a {
    color: #707070;
    display: block;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 14px;
    outline: 0 none;
    text-decoration: none;
}
#menu li:hover a {
    color: #161616;
}
#menu li .drop {
    background: rgba(0, 0, 0, 0) url("../../images/drop.png") no-repeat scroll right 1px;
    padding-right: 21px;
    z-index: 9999;
}
#menu li:hover .drop {
    background: rgba(0, 0, 0, 0) url("../../images/drop.png") no-repeat scroll right 1px;
}
.dropdown_1column, .dropdown_2columns, .dropdown_3columns, .dropdown_4columns, .dropdown_5columns {
  background: rgba(0, 0, 0, 0) linear-gradient(to top , #eeeeee, #bbbbbb) repeat scroll 0 0;
  border-image: none;
  border-radius: 0 5px 5px;
  border: 1px solid #d3d3d3;
  border-top: medium none currentColor;
  float: left;
  left: -999em;
  margin: 4px auto;
  padding: 10px 5px 10px 14px;
  position: absolute;
  text-align: left;
}
.dropdown_1column {
    width: 140px;
}
.dropdown_2columns {
    width: 280px;
}
.dropdown_3columns {
    width: 630px;
    z-index: 1000;
}
.dropdown_4columns {
    width: 560px;
}
.dropdown_5columns {
    width: 700px;
}
#menu li:hover .dropdown_1column, #menu li:hover .dropdown_2columns, #menu li:hover .dropdown_3columns, #menu li:hover .dropdown_4columns, #menu li:hover .dropdown_5columns {
    left: -10px;
    top: auto;
}
.col_1, .col_2, .col_3, .col_4, .col_5 {
    display: inline;
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
}
.col_1 {
    width: 180px;
}
.col_2 {
    width: 270px;
}
.col_3 {
    width: 410px;
}
.col_4 {
    width: 550px;
}
.col_5 {
    width: 690px;
}
#menu .menu_right {
    float: right;
    margin-right: 0;
}
#menu li .align_right {
    border-radius: 5px 0 5px 5px;
}
#menu li:hover .align_right {
    left: auto;
    right: -1px;
    top: auto;
}
#menu p, #menu h2, #menu h3, #menu ul li {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 12px;
    line-height: 21px;
    text-align: left;
    text-shadow: 1px 1px 1px #ffffff;
}
#menu h2 {
    border-bottom: 1px solid #666666;
    font-size: 21px;
    font-weight: 400;
    letter-spacing: -1px;
    margin: 7px 0 14px;
    padding-bottom: 14px;
}
#menu h3 {
    border-bottom: 1px solid #888888;
    font-size: 14px;
    margin: 7px 0 14px;
    padding-bottom: 7px;
}
#menu p {
    line-height: 18px;
    margin: 0 0 10px;
}
#menu li:hover div a {
    color: #000;
    font-size: 13px;
    padding-left: 5px;
}
#menu li:hover div a:hover {
    background-color: #fff;
    color: #000;
}
.strong {
    font-weight: bold;
}
.italic {
    font-style: italic;
}
.imgshadow {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #d3d3d3;
    box-shadow: 0 0 5px #666666;
    margin-top: 5px;
    padding: 4px;
}
.img_left {
    float: left;
    margin: 5px 15px 5px 5px;
    width: auto;
}
#menu li .black_box {
    background-color: #333333;
    border-radius: 5px;
    box-shadow: 0 0 3px #000000 inset;
    color: #eeeeee;
    padding: 4px 6px;
    text-shadow: 1px 1px 1px #000;
}
#menu li ul {
    list-style: outside none none;
    margin: 0 0 12px;
    padding: 0;
}
#menu li ul li {
    float: none;
    font-size: 12px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    text-shadow: 1px 1px 1px #ffffff;
    width: 130px;
}
#menu li ul li:hover {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    margin: 0;
    padding: 0;
}
#menu li .greybox li {
    background: #eee none repeat scroll 0 0;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    margin: 0 0 4px;
    padding: 4px 6px;
    width: 116px;
}
#menu li .greybox li:hover {
    background: #ffffff none repeat scroll 0 0;
    border: 1px solid #aaaaaa;
    margin: 0 0 4px;
    padding: 4px 6px;
}



.divider-vertical {
    height: 50px;
    border-left: 1px solid gray;
    float: left;
    opacity: 0.5;
    margin: 0 15px;
}

.topbarnav {
  margin: 8px 0 0 0 !important;
}

.btn-default {
  border-radius: 4px;
}

.btn-large-block {
  display: block;
  max-width: 148px;
  width: 100%;
  margin: 0 auto 5px;
  text-align: center;
  padding: 5px 10px;

  background: #c9c9c9; /* Old browsers */
  background: -moz-linear-gradient(top, #e5e5e5 0%, #c9c9c9 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e5e5e5 0%,#c9c9c9 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e5e5e5 0%,#c9c9c9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#c9c9c9',GradientType=0 ); /* IE6-9 */

  border-radius: 10px;

  span {
    display: block;
    text-align: center;
  }

  &:hover, &:active {
    background: #e5e5e5; /* Old browsers */
    background: -moz-linear-gradient(top, #c9c9c9 0%, #e5e5e5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #c9c9c9 0%,#e5e5e5 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #c9c9c9 0%,#e5e5e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c9c9c9', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */
  }
}

.topbarkw {
  border-radius: 4px 0 0 4px !important;
}

.layoutselector {
  margin: 9px 0 0 0;
}

#page_title {
  color: $text-color;
  display: table-cell;
  font-size: 20px;
  font-weight: 100;
  height: 21px;
  min-width: 300px;
}

#page_title_line {
  background: rgba(0, 0, 0, 0) url("/images/seperator.png") repeat-x scroll center center;
  display: table-cell;
  height: 21px;
  vertical-align: top;
  width: 100%;
}

.item-title {
  font-size: 14px !important;
}

section#auction_listing.listing div.listing-items div.item-row.list div.item.item-auction p.item-id a {
  color: #4a802b;
  text-decoration: underline;
}

.dropdown-menu.sub-nav {
  background-color: $header-bg;
  li > a:hover {
    background-color: $header-item-hover;
  }
}

.auction_time_remaining {
  color: #b70808;
  font-size: 18px;
  padding-left: 15px;
  width: 240px;
}

div#subnav div.container section div.row div.col-lg-12 h2 {
  color: #000 !important;
}

div#subnav div.container section div.row div.col-lg-12 section#lot_listing.listing div.listing-header div.auction-header div div h1 {
  color: #000 !important;
}

div#subnav div.container section div.row div.col-lg-12 section#lot_listing.listing div.listing-header div.auction-header div.col-lg-12 div.col-lg-5 div div div.row {
  color: #b70808 !important;
}

div#subnav div.container section div.row div.col-lg-12 section#lot_listing.listing div.listing-header div.auction-header div.col-lg-12 div.col-lg-5 div div h5 {
  color: #888 !important;
}

#page_title_subtext {
  display: table-cell;
  font-size: 14px;
  color: #000;
}

.bottom_line {
  border-bottom: 1px solid #e0e1e4;
  clear: both;
  padding-bottom: 8px;
  width: 100%;
}

div.container div.wideform_admin ol li a {
  color: #4a802b !important;
  text-decoration: underline !important;
}

div.container div.wrapper.wideform_admin div.row {
  padding: 0 8px 0;
}

a.button_grey, input.button_grey[type="submit"] {
  background-color: #aaa;
  border: medium none;
  -moz-border-radius: 3px;
  border-radius: 3px;
  color: #222;
  cursor: pointer;
  font-size: 18px;
  font-weight: 900;
  text-decoration: none;
  z-index: 1000;
  padding: 9px 18px;
}

a.button_grey:hover, input[type="submit"].button_grey:hover {
    background-color: #ccc;
    color: #000;
}

a.button_grey_small, input.button_grey[type="submit"] {
  background-color: #aaa;
  border: medium none;
  border-radius: 3px;
  color: #222;
  cursor: pointer;
  font-size: 10px;
  font-weight: 900;
  padding: 6px 18px;
  text-decoration: none;
  z-index: 1000;
}

a.button_grey_small:hover, input[type="submit"].button_grey:hover {
    background-color: #ccc;
    color: #000;
}

a.button_green, input[type="submit"].button_green, input[type="button"].button_green {
    z-index: 1000;
    font-size: 18px;
    font-weight: 900;
    border: none;
    cursor: pointer;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 9px 18px;
    text-decoration: none;
    background-color: #4a802b;
    color: #ffffff;
}

a.button_green:hover, input[type="submit"].button_green:hover {
    background-color: #7ca826;
}

a.button_red, input.button_red[type="submit"] {
  background-color: #8c1717;
  border: medium none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 900;
  left: 420px;
  padding: 9px 18px;
  text-decoration: none;
  text-shadow: 1px 1px 2px #000;
  top: -190px;
}

a.button_lt_green, input.button_lt_green[type="submit"], input.button_lt_green[type="button"] {
  background-color: #97c47b;
  border: medium none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 900;
  padding: 9px 18px;
  text-decoration: none;
  z-index: 1000;
}

a.button_lt_green:hover, input[type="submit"].button_green:hover {
    background-color: #7ca826;
}

a.button_lt_green_small, input.button_lt_green[type="submit"], input.button_lt_green[type="button"] {
  background-color: #97c47b;
  border: medium none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  font-size: 10px;
  font-weight: 900;
  padding: 9px 18px;
  text-decoration: none;
  z-index: 1000;
}

a.button_lt_green_small:hover, input[type="submit"].button_green:hover {
    background-color: #7ca826;
}

.auction_description {
  width:100%;
  height:200px;
  overflow:auto;
  border:1px solid #ccc;
  border-radius:0 0 5px 5px;
  -moz-border-radius:0 0 5px 5px;
  margin-bottom:25px;
  box-shadow:0 5px 10px #ccc;
  -moz-box-shadow:0 5px 10px #ccc;
  border-top:none;
  padding: 2px 0 0 6px;
}

#view_more_toggle {
  border-bottom:1px solid #ccc;
  text-align:center;
  margin-bottom:0;
  margin-top:10px;
  padding: 0 50px 8px;
  display: inline-block;
}

#view_more_toggle_up {
  border-bottom:1px solid #ccc;
  text-align:center;
  margin-bottom:0;
  margin-top:10px;
  padding: 0 50px 8px;
  display: inline-block;
}

#descheader {
  border-bottom:1px solid #ccc;
  text-align:center;
  margin-bottom:0;
  margin-top:10px;
  padding-top:0;
}

.genericnavbox {
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, #ffffff 0%, #efefef 47%, #ededed 100%) repeat scroll 0 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 1px 2px 2px #eee;
  margin: 0 auto;
  padding: 5px;
  display: inline-block;
  width: 100%;
}

.bidbox {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 3px 5px #ddd;
}

.textCenter {
  text-align: center;
}

.red {
  color: #b70808 !important;
}

.stButton {
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  line-height: 16px;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
  text-decoration: none;
  z-index: 1;
}

div.container div.wrapper.wideform_admin div.row div.col-lg-12.text-center a span {
  color: #4a802b;
  text-decoration: underline;
}

a.glossary_link {
  color: #394376 !important;
  text-decoration: underline;
}



#topbar_bubble {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 3px 5px #ccc;
  margin-top: -6px;
  padding: 0;
  text-align: left;
  width: 195px;
}

.dropdown_1column, .dropdown_2columns, .dropdown_3columns, .dropdown_4columns, .dropdown_5columns {
  background: #eee;
  background: -moz-linear-gradient(to top,#eee,#bbb);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#EEEEEE), to(#BBBBBB));
  background: linear-gradient(to top,#eee,#bbb);

  border-radius: 0 5px 5px;
  border: 1px solid #d3d3d3;
  border-top: medium none currentColor;
  float: left;
  left: -999em;
  margin: 4px auto;
  padding: 10px 5px 10px 14px;
  position: absolute;
  text-align: left;
}

.page_topper_admin {
  background: rgba(0, 0, 0, 0) url("/images/page_title_bg.png") repeat-x scroll 0 0;
  height: 50px;
  margin: 0 auto;
  padding-bottom: 22px;
  text-align: left;
  width: 1000px;
}

.page_title_admin {
  background: #FAFAFA none repeat scroll 0 0;
  color: #800000;
  font-size: 20px;
  font-weight: 700;
  padding-right: 28px;
  padding-top: 4px;
}

.home_underslider_header {
  font-size: 29px;
  font-weight: bold;
}

a.home_underslider_learnmore {
  color: #800000;
  font-size: 13px;
  text-transform: lowercase;
}

div.container-full-bg div.container div.col-lg-4 div h1 {
  color: #800 !important;
}

#facebook-fa {
    color: #3B5998;
    text-shadow: 1px 1px 1px #ccc;
}

#pinterest-fa {
    color: #BB1B2B;
    text-shadow: 1px 1px 1px #ccc;
}

#youtube-fa {
    color: #E31723;
    text-shadow: 1px 1px 1px #ccc;
}

#twitter-fa {
    color: #0084B4;
    text-shadow: 1px 1px 1px #ccc;
}


.form-split-border-left {
    border-left: 1px solid #ccc;
    padding-left: 20px;
    /*padding-left: 20px;
    margin-left: 20px;*/
}

form#login {
  label {
      font-size: 10px;
      color: #555;

      &.chk_label {
        font-size: 13px;
      }
  }

  input[type=text], input[type=password] {
    background: #f1f1f1 no-repeat;
    padding: 5px;
    margin: 0 0 10px 0;
    border: 1px solid #ccc;

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    -webkit-box-shadow: 0 1px 1px #ccc inset, 0 1px 0 #fff !important;
    -moz-box-shadow: 0 1px 1px #ccc inset, 0 1px 0 #fff !important;
    box-shadow: 0 1px 1px #ccc inset, 0 1px 0 #fff !important;

    &:focus {
      background-color: #fff;
      border-color: #e8c291;
      outline: none;
      -webkit-box-shadow: 0 0 0 1px #e8c291 inset !important;
      -moz-box-shadow: 0 0 0 1px #e8c291 inset !important;
      box-shadow: 0 0 0 1px #e8c291 inset !important;
    }
  }
}

#auction_map_canvas {
  width:100%;
  height:500px;
  border: 1px solid rgb(68, 68, 68);
  float: left;
  position: relative !important;
  background-color: rgb(229, 227, 223) !important;
  overflow: hidden !important;
}

.wideform {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 7px #bbb;
  margin: 0 auto;
  padding: 30px;
  height: 560px;
}

div.container-full-bg div.container section div.row div.col-lg-3 {
  top: 0;
}

/*.tile_auction {
  float: left;
  font-size: 12px;
  height: 308px;
  margin: 15px;
  overflow: hidden;
  padding: 0 !important;
  text-align: center;
  width: 175px !important;
}*/



.timer_outer {
  background: #f1f1f1 none repeat scroll 0 0;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
  margin-top: 10px;
  height: 24px;
}

.announcement {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  margin-top: 18px;
}

.btn-grey {
  background: $grey-color;
  border-color: $grey-color;
  color: #000;
  border-radius: 0 !important;
}

.btn-grey:hover {
  background: darken($grey-color, 15%);
  border-color: darken($grey-color, 15%);
}

.btn-grey:active {
  background: darken($grey-color, 15%);
  border-color: darken($grey-color, 15%);
}

.btn-lt-green {
  background: #97c47b none repeat scroll 0 0;
  border-color: #97c47b;
  color: #FFF;
  border-radius: 0 !important;
}

.btn-lt-green:hover {
  background: #4a802b none repeat scroll 0 0;
  border-color: #4a802b;
  color: #FFF;
}

.btn-lt-green:active {
  background: #4a802b none repeat scroll 0 0;
  border-color: #4a802b;
  color: #FFF;
}

#watchlist_search > a > h1 {
  color: #fff !important;
}

#remote-modal > div > div > div.modal-header > h1 {
  color: #fff !important;
}

div.container-full-bg div.container div.col-lg-12 h4 {
  color: #800 !important;
}

.lot-row {
  padding: 10px;
  width:100%;
}

.lot-row + .lot-row {
  border-top: 2px solid $primary-color;
}

#bid_listing > div > div > div.listing-items.col-lg-12 > div > div > div.col-lg-6.col-lg-offset-6 > div > div > div.lot-time-remaining.time-box .time-element {
  padding-top: 20px !important;
}

.time-box-mybids .time-element-mybids {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #AF0000;
}

div.container div#lot_listing.row div.col-lg-12 h1{
  padding-top: 4px !important;
}

div.container div#lot_listing.row div.col-lg-12 hr{
  margin-top: 0 !important;
}
ul.socialicons {
	list-style: none;
  padding:0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

ul.socialicons li {
  display: inline-block;
  margin-bottom: 20px;
}


div#subnav div.container-full-bg div.container section div.row div.col-lg-12 section#auction_listing.listing div.listing-items div.item-row.list div.item.item-auction {
  padding: 8px !important;
}

div#subnav div.container-full-bg div.container section div.row div.col-lg-12 section#lot_listing.listing div.listing-items.col-md-12 div.item-row.list div.col-lg-12.lot-row {
  padding: 8px !important;
}

.featuredh1 {
  padding-left:8px;
  font-size:23px;color:#800 !important;
}

body > div > div.container > div.col-lg-12.hidden-custom > div > button > h1 {
  font-size:23px;color:#800 !important;
}

#logo > a > img{
  @media (max-width:500px) {
    max-width: 100% !important;
  }
}

@media (max-width: 1199px) {
  #logo > a > img,
  #lot_listing > div > div > div {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .infolink {
    background-color: #d5d5d5;
    border-left: 1px solid #fff;
    cursor: pointer;
    font-size: 13px;
    margin-right: 8px;
    width: auto !important;
  }
  .slider_area {
    padding: 5px;
  }
  #homeSlider > div > div.sp-mask.sp-grab > div > div:nth-child(1) {
    width: 100% !important;
  }
  #auction_listing > div.listing-items > div > div > div:nth-child(1) > p > a > img {
    max-width: 100% !important;
  }
  .hidden-custom {
    display: block !important;
  }
  #categories > li {
    padding-left: 8px;
  }
}

@media (max-width: 994px) {
  .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
    @media (max-width: $screen-xs-max) {
      margin:0;
    }
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .collapse.in{
      display:block !important;
  }
  #navbar, #dashboard {
    float: none;
  }
}

dl.ship_options {
  dd + dt {
    margin-top: 7px;
  }
}

.listing-items .list .item-auction { padding: 10px; }

#at-cv-lightbox{
  display: none!important;
}

.red-bar{
  display: inline-block;
  text-transform: uppercase;
  width: 100%;
  background-color: $header-color;
  color: #FFF;
  font-size: 20px;
  padding: 9px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.red-bar a{
  color: #FFF;
}


.selling-img-circle {
  display: inline-block;
  margin: 0 10px;
  font-size: 18px;
  width: 25%;
  min-width: 175px;

  .img, .circle {
    background: no-repeat 50%;
    border-radius: 50%;
    background-size: cover; 
    width: 100%;
  }
}

.one-to-one {
  margin-top: 100%;
  display: inline-block;
  height: 1px;
  width: 100%;
}

.franchise_logo {
  max-width: 100%;
  width: 340px;
}

.btn.no-radius {
  border-radius: 0;
}

@import 'tables';
