
form .fa-cog { display: none; }
form.submitting .fa-cog {
  display: inline-block;
  width: 32px;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:focus {
  @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075) !important);
}

.form-group {
  margin-bottom: 10px;

  label { margin-bottom: 5px; font-size: 14px; color: $primary-color; vertical-align: baseline; }

  &.row {
    margin-left: -5px; margin-right: -5px;
    > div[class^="col-"] { padding-left: 5px; padding-right: 5px; }
  }
}

.input-group.date .input-group-addon { cursor: pointer; }

.input-group .input-group-btn .btn {
  height: 34px;
  line-height: 20px;
  border-bottom-right-radius: $input-border-radius;
  border-top-right-radius: $input-border-radius;
}

.chosen-select { display: none; }
.chosen-container {
  font-size: $font-size-base;
  width: 100% !important;

  .chosen-drop {
    @include box-shadow(none);
    border-top: 0 !important;
    border-radius: 0 0 $input-border-radius $input-border-radius !important;
  }
  .chosen-results li { white-space: nowrap; }
}

.chosen-container-single .chosen-single, .chosen-container-multi .chosen-choices {
  color: $input-color;
  background: $input-bg !important;
  border: 1px solid $input-border;
  border-radius: $input-border-radius;

  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075) !important);
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
}

.chosen-container-single .chosen-single { padding: 4px 12px 6px 12px; }
.chosen-container-multi .chosen-choices { padding: 4px 1px 4px 6px;; }

.chosen-container-single .chosen-single {
  height: 34px;

  div {
    width: 16px;
    b { top: 4px; position: relative; }
  }
}

.chosen-container-active.chosen-with-drop .chosen-choices {
  border-radius: $input-border-radius $input-border-radius 0 0 !important;
}

.chosen-container-multi .search-choice {
  font-size: 13px;
  line-height: 13px;
  background-image: none !important;
  border-color: $input-border !important;
  @include box-shadow(none !important);
}
.chosen-container-multi .search-field {
  padding: 0 0 0 5px !important;
}

.chosen-container-active .chosen-single,
.chosen-container-active .chosen-choices,
.chosen-container-active .chosen-drop,
.chosen-container-active.chosen-with-drop .chosen-single,
.chosen-container-active.chosen-with-drop .chosen-choices,
.chosen-container-active.chosen-with-drop .chosen-drop {
  border-color: $input-border-focus;
  outline: 0;
}

.chosen-drop .card {
  img { margin-right: 10px; vertical-align: top; }
  p { display: inline-block; line-height: 31px; }
}

.chosen-single.card-single {
  padding: 4px;
  height: auto;
  div b { top: 8px; }

  .card {
    img { margin-right: 10px; vertical-align: top; }
    p { display: inline-block; line-height: 31px; }
  }
}

.has-error {
  .chosen-container-single .chosen-single, .chosen-container-multi .chosen-choices,
  .chosen-container-active .chosen-single,
  .chosen-container-active .chosen-choices,
  .chosen-container-active .chosen-drop,
  .chosen-container-active.chosen-with-drop .chosen-single,
  .chosen-container-active.chosen-with-drop .chosen-choices,
  .chosen-container-active.chosen-with-drop .chosen-drop {
    border-color: $state-danger-text !important;
  }
}

.slider
{
  .tooltip { padding: 0; }
  .tooltip-arrow { display: none; }
  .tooltip-inner {
    padding: 0;
    color: $primary-color;
    background: transparent;
    font-weight: bold;
  }

  &.slider-horizontal { width: 100%; }
  .slider-track { background: darken($grey-color, 15%); }
  .slider-selection { background: $primary-color; }
}

.slider-handle {
  background-color: lighten($primary-color, 30%);
  background-image: none;
}

.datepicker { padding: 4px !important; }

.onoffswitch {
  @include user-select(none);

  input { display: none; }

  label {
    cursor: pointer;
    display: inline-block;
    margin: 0;
    width: 64px;
    overflow: hidden;
    background-color: $grey-color;
    font-size: 12px;
    text-align: left;
  }

  span {
    display: block;
    width: 200%;
    white-space: nowrap;
    @include transition(margin 0.3s ease-in 0s);

    &:before, &:after {
      display: inline-block;
      padding: 4px 0;
      color: #fff;
      text-align: center;
      width: 35%;
    }
    &:before {
      margin-left: -35%;
      background-color: $primary-color;
      content: 'ON';
    }
    &:after {
      margin-left: 15%;
      background-color: darken($grey-color, 30%);
      content: 'OFF';
    }
  }

  input:checked + label span { margin-left: 71%; }
}

label.onoffswitch { cursor: pointer; margin: 0; padding: 3px 0 10px 0; }

.yesnoswitch {
  @extend .onoffswitch;

  span {
    &:before {
      content: 'YES';
    }
    &:after {
      content: 'NO';
    }
  }
}
label.yesnoswitch { cursor: pointer; margin: 0; padding: 3px 0 10px 0; }

.screen-reader {
  position: fixed;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}