.lot-details-page{
  .flexbox{
    display: flex;
    &.column-mobile {
      @media (max-width: 991.9px) {

        flex-direction: column;
        justify-content: center;
        a{
          text-align: center;
        }
      }
    }
    &.space-between{
      justify-content:space-between;
    }
    &>.flex-grow-1{
      flex-grow: 1;
    }
  }
}