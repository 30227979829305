
@import "../bootstrap/mixins";

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin input-placeholder {
  &::-webkit-input-placeholder { @content }
  &:-moz-placeholder           { @content }
  &::-moz-placeholder          { @content }
  &:-ms-input-placeholder      { @content }
}

@mixin transform($transform) {
  -webkit-transform: $transform;
      -ms-transform: $transform;
       -o-transform: $transform;
          transform: $transform;
}

@mixin fa-li() {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 5px;
}