
@import "../bootstrap/variables";

$main-background: #fff;

$primary-color: #6f7d8f;
$success-color: #5cb85c;
$info-color:    #5bc0de;
$warning-color: #f0c037;
$danger-color:  #880000;
$grey-color: #c5c8c6;


$primary-text: #f2f2f2;
$primary-hover: darken($primary-color,25%);
$primary-hover-text:#f2f2f2;

$header-bg: #F2E6DA;
$header-item-hover: #dde4fc;
$header-color: #2d4155;

$footer-bg: #eaefff;
$footer-color: #2d4155;

$text-color: #6f7d8f;
$text-color-hover: darken($text-color, 15%);

$pfont: 'EB Garamond', sans-serif;

$grid-gutter-width: 25px;
$modal-sm: 400px;

$spin-color: #233646;
$spin-margin: 0 auto;


$screen-lg-min: 1350px;
$screen-md: 995px;
$screen-md-min:$screen-md;
$container-lg:1300px;