
#navbar form{
  background-color: #DDE4FC;
  padding-left: 10px;
  &:before{
    content: ' ';
  }
}
.cross-branding{
  height:64px;
  background-color:#eee;
  color:black;
  border:none;
  white-space:pre-wrap;
  width: 100%;
  font-weight:900;
  img{
    height:50px;
    margin-right: 10px;
  }
  &:hover{
    background-color: #ccc;
  }
}

@media (max-width: 994px) {
  body.stick > nav.navbar {
    display: none;
  }
  aside nav.navbar.navbar-default {
    transition: all 0.5s ease;
  }
  body.stick aside nav.navbar.navbar-default {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
  }
}