.slider_area{
  width: 100%;
  @media(min-width:$screen-sm-min) {
    width: 66%;
  }
}
.buying-home{
  flex-grow: 0;
  flex-shrink: 1;
  & img{
    max-height: 150px;
    width: 100%;
  }
  margin-bottom: 0;
  @media(min-width:$screen-sm-min){
    flex-grow: 1;
    margin-bottom: 10px;
    margin-right: 0 !important;
    & img{
      max-height: initial;
    }
  }
}

.maps-home{
  flex-grow: 0;
  & img{
    max-height: 150px;
    width: 100%;
  }
  flex-shrink: 1;

  @media(min-width:$screen-sm-min){
    flex-grow: 1;
    & img{
      max-height: initial;
    }
  }
}