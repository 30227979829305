.winning-text{
  &.is-winning{
    color: green;
    &.lot-ended::after{
      content: 'Won';
    }
    &.lot-active::after{
      content: 'Currently Winning';
    }
  }
  &.is-not-winning{
    color: red;
    &.lot-ended::after {
      content: 'Lost';
    }

    &.lot-active::after {
      content: 'Not Currently Winning';
    }
  }
  font-size: 16px;
  font-weight: bold;
}