.home-auctions{
  display:grid;
  grid-template-columns:minmax(290px,375px);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  margin-bottom: 150px;
  @media (min-width: 700px){
    grid-template-columns: repeat(2, minmax(290px,375px));
  }
  @media (min-width: $screen-md-min) {
    grid-template-columns:repeat(3, minmax(290px,375px));
  }
  @media (min-width: $screen-lg-min) {
    grid-template-columns:repeat(4, minmax(290px,375px));
  }
}

.tile-thumbnails{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 5px;
  min-height: 50px;
}

.tile-thumbnails>div {
  max-width: 30%;
}
.auction-tile{
  //TODO Change to use variable
  background-color:#f2f2f2;
  color: #6f7d8f;
  font-size: 1.1em;
  transition: box-shadow .2s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  &:hover{
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 2px 4px rgba(0, 0, 0, 0.24);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 2px 4px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
    text-decoration: none;
  }
  &:focus{
    color: inherit;
    text-decoration: none;
  }
  .item-image-slider{
    border: none;
    border-radius: 10px;
    height: 200px;
    li{
      width: 100%;
      display: inline-block;
      img{
        max-height:200px;
        max-width: 287px;
        width: auto;
        height: auto;
      }
    }
    img{
      width: 100%;
    }

    .prev, .next{
      display: flex;
      align-items: center;
      font-size: 1.2em;
      &:hover{
        text-decoration: none;
      }
    }
  }

  .tile-time-left{
    margin: 10px;
    font-weight: bold;
    font-size: 1em;
    .indicator{
      color: #c89368;

      &.low-time{
        color: #cb2026;
      }
    }
  }

  .auction-item-count{
    background-color: #aaa;
    padding: 0 20px;
    color: white;
    border-radius: 10px;
    line-height: 1.5em;
    white-space: nowrap;
  }

  a{
    color: #6f7d8f;
  }
}
.auction-listing-items{
  display:grid;
  grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
  grid-gap: 20px;
}
.item{
  &.item-auction{
    display: flex;
  }
}
.show-mobile{
  display: none;
}
.button-container{
  display: flex;
  justify-content:space-between;
  align-items: center;
  &>a{
    max-width: 45%;
  }
}

.hidden-tablet{
  display: block;
}
@media (max-width: 768px) {
  .hidden-mobile{
    display:none !important;
  }
  .show-mobile{
    display: block;
  }

  .button-container{
    flex-direction: row-reverse;

  }
  .item{
    &.item-auction{
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
}
@media (min-width: $screen-sm-min) {
  .hidden-tablet{
    display: none;
  }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
  .hidden-tablet{
    display: block;
  }
}