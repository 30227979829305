@media only screen and (max-width: 800px) {
  /* Force table to not be like tables anymore */
  .mtable {
    &,
    & > thead,
    & > tbody,
    & > tfoot {
      display: block;

      & > tr {
        display: block;

        & > th, & > td {
          display: block;
        }
      }
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    & > thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    & > tbody, & > tfoot {
      & > tr {
        border: 1px solid #ccc;

        & > td, & > th {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee; 
          position: relative;
          padding-left: 50%; 
          white-space: normal;
          text-align:left;

          &:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%; 
            padding-right: 10px; 
            white-space: nowrap;
            text-align:left;
            font-weight: bold;
            /* Label the data */
            content: attr(data-title); 
          }
        }
      }
    }
  }
}