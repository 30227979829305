@import 'variables';



// db   db d88888b  .d8b.  d8888b. d88888b d8888b.
// 88   88 88'     d8' `8b 88  `8D 88'     88  `8D
// 88ooo88 88ooooo 88ooo88 88   88 88ooooo 88oobY'
// 88~~~88 88~~~~~ 88~~~88 88   88 88~~~~~ 88`8b
// 88   88 88.     88   88 88  .8D 88.     88 `88.
// YP   YP Y88888P YP   YP Y8888D' Y88888P 88   YD
.navbar{
  min-height: inherit;
}
.navbar-collapse{
  padding-left: 0;
  padding-right: 0;
}
.navbar-inverse {
  background-color: $header-bg;

  border: 0 solid transparent;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 40px;
  text-transform: uppercase;
  .nav>li>a {
    font-size: 14px;
    position: relative;
    display: block;
    padding: 15px 15px;
  }
  .navbar-nav > .active > a {
    background-color: $primary-hover;
    color: $primary-hover-text;
    &:hover, &:focus {
      background-color: $primary-hover;
      color: $primary-hover-text;
    }
  }
  .navbar-brand {
    color: #E3E3E3;
  }

  .navbar-nav > li > a {
    color: $header-color;
    &:hover, &:focus {
      background-color: $header-item-hover;
      color: darken($text-color,15%);
    }

  }

}
#navbar{
  @media (min-width: $screen-md) {
  display:flex !important;
  align-items:stretch;
  float:none;
  }
  @media (max-width: $screen-md) {
    &>form{
      margin-top: 10px;
    }
  }
}

// d8888b. db    db d888888b d888888b  .d88b.  d8b   db .d8888.
// 88  `8D 88    88 `~~88~~' `~~88~~' .8P  Y8. 888o  88 88'  YP
// 88oooY' 88    88    88       88    88    88 88V8o 88 `8bo.
// 88~~~b. 88    88    88       88    88    88 88 V8o88   `Y8b.
// 88   8D 88b  d88    88       88    `8b  d8' 88  V888 db   8D
// Y8888P' ~Y8888P'    YP       YP     `Y88P'  VP   V8P `8888Y'

label, .btn {
  @include user-select(none)
}

.btn {
  font-weight: 600;
  &.disabled, &[disabled] { cursor: default; }
  &.btn-icon-left span { padding-left: 6px; }
  &.btn-icon-right span { padding-right: 6px; }
}

.btn.active, .btn:active {
  @include box-shadow(none);
}

.btn-default, .btn-primary, .btn-success, .btn-danger {
  @include border-radius(0);
  @include box-shadow(none !important);
  text-transform: uppercase;
  font-size: 13px;
}

.btn-create-account{
  margin-left: 10px;
  border: 2px solid #efeae7;
  padding: 0.4rem 0.65rem;
  color: #505050;
  background-color: #efeae7;
  font-size: 0.9rem;
  text-transform: none;
  &:hover{
    border: 2px solid $primary-hover;
    color: $primary-hover-text;
    background-color: $primary-hover;
  }
}

.btn-white{
  margin-left: 0.2rem;
  border: 2px solid #efeae7;
  padding: 0.4rem 0.65rem;
  color: #505050;
  background-color: #fff;
  font-size: 0.9rem;
  text-transform: none;
  &:hover{
    border: 2px solid $primary-hover;
    color: #000;
  }
}

.btn-default {
  background: $grey-color;
  border-color: $grey-color;

  &:hover, &:focus, &:active, &.active {
    background: darken($grey-color, 10%) !important;
    border-color: darken($grey-color, 10%) !important;
  }
}

.panel-default .btn-default {
  background: darken($grey-color, 30%);
  color: #fff;
  border: darken($grey-color, 30%);
  font-weight: bold;

  &:hover, &:focus, &:active, &.active {
    background: darken($grey-color, 40%) !important;
    border-color: darken($grey-color, 40%) !important;
  }
}

.btn-primary {
  background: $primary-color;
  border-color: $primary-color;
  color: $primary-text;
  &:hover, &:focus, &:active, &.active {
    background: $primary-hover;
    border-color: $primary-hover;
    color: $primary-hover-text;
  }
}
.btn-inverse {
  border-width: 2px;
  background: $primary-text;
  border-color: $primary-color;
  color: $primary-color;
  &:hover, &:focus, &:active, &.active {
    background: $primary-color;
    border-color: $primary-color;
    color: $primary-text;
  }
}

.btn-success {
  background: $success-color;
  border-color: $success-color;

  &:hover, &:focus, &:active, &.active {
    background: darken($success-color, 10%) !important;
    border-color: darken($success-color, 10%) !important;
  }
}

.btn-link {
  color: darken($grey-color, 40%);
  &:hover, &:focus, &:active, &.active { color: darken($grey-color, 50%) !important; }

  &.btn-primary, &.btn-danger, &.btn-success {
    background: transparent !important;
  }

  &.btn-primary, &.btn-primary:focus, &.btn-primary:active {
    color: $primary-color;
    border: 0;
  }
  &.btn-primary:hover { color: darken($primary-color, 10%) !important; }

  &.btn-danger, &.btn-danger:focus, &.btn-danger:active {
    color: $danger-color;
    border: 0;
  }
  &.btn-danger:hover { color: darken($danger-color, 10%) !important; }

  &.btn-success, &.btn-success:focus, &.btn-success:active {
    color: $success-color;
    border: 0;
  }
  &.btn-success:hover { color: darken($success-color, 10%) !important; }
}

.btn-group {
  .btn { float: none; }
}

.btn-group.btn-group-spaced {
  .btn + .btn { margin-left: 6px; }
}
