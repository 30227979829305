
#footer {
  p{
    color: #aaa;
  }
  h4{
    color: #ddd;
    font-size: 20px;
    text-transform: uppercase;
    margin: 20px 0;
  }
  h5, a{
    color: #ddd;
    font-size: 14px;
    margin: 20px 0;
  }
  margin-top: 50px;
  .footer-sites {
    padding-bottom: 20px;
  }

  .footer-info {
    padding: 20px 0;
    background: $footer-bg;
    color: $footer-color;

    h3{
      text-transform: uppercase;
      font-size: 17px;
      font-weight: 700;
    }
    a{
      color: $footer-color;
      &:hover{
        color: lighten($primary-color, 30%);
        text-decoration: none;
      }
    }
    hr {
      @include opacity(0);
      margin-bottom: 10px;
    }

  }
  .footer-cross-brand{
    display: block;
    width: 250px;
    height:75px;
    background-color: #ffffff;
    border-radius: 3px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 900;
    padding:9px 18px;
    vertical-align: top;
    color: #000000;
    margin: 25px auto;
    img{
      max-width:50%;
      float: left;
      margin-right: 10px;
    }
    &:hover{
      background-color: #DDDDDD;
      text-decoration: none;
    }
    p{
      color: #000000;
    }
  }
  .social-container{
    font-size: 25px;
  }
  .social-symbol {
    font-size: 35px;
    color: #454545;
    &:hover{
      color:$header-bg;
    }
  }
}
