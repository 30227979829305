.row.flex{
  display: flex;
  margin-left: 0;
  margin-right: 0;
  &.v-center{
    align-items: center;
  }
  div[class*="col-"]{
    width: initial;
  }
}

.flexbox{
  display: flex;
  flex-wrap: nowrap;
  &:not(.flex-col)>*:not(:last-child){
    margin-right: 10px;
  }
  &.flex-mobile-col{
    flex-direction: column;
    @media (min-width: $screen-sm-min) {
      flex-direction: row;
    }
  }
  &.flex-mobile-row{
    flex-direction: row;
    &.space-between{
      margin-bottom: 0;
    }
    @media (min-width: $screen-sm-min) {
      flex-direction: column;
    }
  }
  &.align-center{
    align-items: center;
  }
  &.justify-center{
    justify-content: center;
  }
  &.space-between{
    justify-content: space-between;
  }
  &.space-evenly-mobile{
    @media(max-width:$screen-sm-max){
      justify-content: space-evenly;
    }
  }
  &.wrap{
    flex-wrap: wrap;
  }
  &.flex-col{
    flex-direction: column;
  }
  .justify-self-start{
    margin-right: auto;
  }
  .justify-self-end{
    margin-left: auto;
  }
  @for $i from 1 through 4 {
    .grow-#{$i} {
      flex-grow: $i;
    }
    .mobile-order-#{$i}{
      order: $i;
      @media(min-width:$screen-sm-min){
        order: initial;
      }
    }
  }
}