
.invoice {
  border: 1px solid $input-border;
  padding: 30px;
  background-color: #fff;
  @include box-shadow(0 4px 6px rgba(0, 0, 0, 0.5));

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & > * { flex: 1 0 0; }
    & + .header { margin-top: 10px; }

    .logo {
      padding: 2px;
      text-align: center;
      flex: 1 0 100%;
      width: 100%;

      img {
        max-width: 280px;
        max-height: 100px;
      }
    }

    .bidder {
      flex: 1 0 100%;
      .name { color: #c00000; }
      .biddernum {
        font-weight: bold;
        font-size: larger;
      }
    }

    .details {
      h1 { margin: 0; font-weight: bold; }
      .invoice-num { font-weight: bold; color: #c00000; }
      .invoice-date { font-weight: bold; }
      .auction-details { margin-top: 2em; }
    }

    .pickup {
      flex: 1 0 100%;
      label { font-size: larger; }
      address { margin-bottom: 0; }
      .date { color: #c00000; }

      
    }

    .paid-container {
      .paid {
        width: 80%;
        margin: 0 auto;
        border: 2px solid #ededed;
        border-radius: 2px;
        text-align: center;
        padding: .75em;
        letter-spacing: .75em;
      }
    }

    @media (min-width: 650px) {
      .bidder, .pickup {
        flex: 0 1 auto;
      }
    }
    @media (min-width: 992px) {
      flex-wrap: nowrap;

      .logo {
        flex: 0 1 auto;
        width: auto;
        text-align: left;
      }

      .bidder, .pickup {
        /* padding-left: 8em; */
        flex: 1 0 0;
        display: flex;
        justify-content: center;
      }
    }
  }

  .lot-header { font-weight: bold; }

  .lots {
    .lot {
      display: flex;
      justify-content: space-between;
      page-break-inside: avoid;
      border-top: 1px solid #e7e7e7;

      .col1 { width: 3em; }
      .col2 { flex: 1 0 0; }
      .bidsummary-box {
        .ln {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          & > * { text-align: right; }
          span { width: 6em; }
        }
      }
    }
  }

  .totals-right {
    display: flex;
    justify-content: flex-end;

    .totals {
      margin-top: 1.25em;

      .ln {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e7e7e7;

        & > * { text-align: right; padding: 3px 0; }
        *:nth-child(2) { width: 7em; }
        &:last-child *:last-child { border-bottom: 1px solid #e7e7e7; }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    border-top: 3px solid #c00000;
    margin-top: 15px;
    padding-top: 5px;

    .company-name {
      font-size: larger;
      font-weight: bold;
    }

    .contacts, .company {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
