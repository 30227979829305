.nav-tabs.ao {
  margin-bottom: 0;

  & > li {
    & > a {
      font: 18px Arial;
      font-weight: 900;
      padding: 8px 15px;

      &:hover {
        background-color: #ccc;
        color: #000;
      }
    }

    &.active {
      & > a {
        background-color: #aaa;
        color: #222;
      }
    }
  }
}

.tab-content.ao {
  border: 1px solid #ccc;
  border-top: none;
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  margin-bottom: 25px;
  box-shadow: 0px 5px 10px #ccc;
}