
.sidebar {
  form { margin: 0 0 10px 0; }

  .page-header {
    margin: 0;
    background: $primary-color;
    color: #fff;
    text-align: center;

   .fa {
      position: relative;
      margin: 0;
      top: 2px;
      font-size: 12px;
      @include transition(transform 0.3s ease-in-out);
    }

    &.open .fa {
      @include rotate(180deg);
      @include transform-origin(50% 50%);
    }
  }

  .panel-default {
    margin-bottom: 2px;

    & > .panel-footer {
      text-align: center;
      padding: 0 15px 15px 15px;
    }

    & > .panel-body {
      .form-group:last-child { margin-bottom: 0; }
    }
  }

  .chosen-drop .child { padding-left: 20px; }

  .slider-container { padding: 0 10px 15px 10px; }
}

@media (max-width:767px) {
  .sidebar .page-header {
    margin-top:20px;
    cursor:pointer;
  }
}
