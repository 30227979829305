// .lot-auction-separator {
//   text-transform: uppercase;
//   background-color: #AD0000;
//   color: #FFF;
//   font-size: 20px;
//   padding: 9px 0;
//   margin-top: 20px;
//   margin-bottom: 20px;
//   text-align: center;
//   width: 100%;

//   a {
//     color: #fff;
//   }
// }

// .flex-lot {
//   padding: 10px;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   flex-wrap: wrap;
//   height: 220px;

//   & + & {
//     border-top: 2px solid #AF0000;
//   }
//   &:nth-child(odd) {
//     background-color: #f5f5f5;
//   }

//   .lot-number {
//     flex: 0 1 auto;
//   }
//   .lot-image {
//     width: 218px;
//     height: 164px;
//     line-height: 164px;
//     background-color: #fff;
//     border: 1px solid #999;
//     text-align: center;

//     a {
//       display: block;
//       height: 162px;
//       line-height: 162px;
//       text-align: center;

//       img {
//         max-height: 161px;
//         display: inline-block;
//         vertical-align: middle;
//       }
//     }
//   }

//   .lot-info {
//     width: calc(100% - 525px);
//   }

//   .lot-actions {
//     width: calc(100% - 525px);

//     @media (min-width: $screen-md) {
//       display: inline-flex;
//       justify-content: space-around;
//     }
//   }

//   .bidbox {
//     width: 300px;
//     min-height: 164px;
//   }
// }

a.item-location, a.item-bid-count {
  color: #b70808;
  text-decoration: underline;
}
#auction-details, .item-description, #page_title_container {
  a {
    color: #b70808;
    text-decoration: underline;
  }
}

.item_lot_location {
  font-weight: 400;
}

@media (max-width: $screen-md) {
  .list-time-box>div {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
  }
  .bidbox {
    font-size: larger;
  }
  .current_bid{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    &>*{
      white-space: nowrap;
    }
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-md) {
  .lot-buttons{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .btn{
      max-width: 45%;
    }
    .btn-block+.btn-block{
      margin-top: 0 !important;
    }
  }
}

@media (max-width: $screen-md) {
  input[type=number]{
    -moz-appearance: textfield;
  }
  .form-control{
    padding:4px 10px;
  }
}

@media (min-width: $screen-sm-min) {
  .top-20{
    top:20% !important;
  }

  .top-10{
    top:10% !important;
  }
}