.line{
  flex-grow: 2;
  border: 1px solid $primary-text;
  height: 1px;
  margin-top: 15px;
}

.header-text{
  font-size: 1.5rem;
}

.lighter-header{
  font-size: 16px;
  color: #c89368;
  font-weight: 600;
  text-transform: uppercase;
}